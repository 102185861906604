//
//  Page Wrapper
//
.page-wrapper {
	// display: flex;
	flex: 0 0 100%;
	padding-right: 0;
	padding-left: 0;
	overflow: hidden;
	flex-direction: column;
	@media (min-width: 1024px) {
		flex-direction: row;
	}
}
