.form-floating {
	&:not(.form-control:disabled)::before {
		content: none;
	}

	> .form-control:focus,
	> .form-control:not(:placeholder-shown),
	> .form-control-plaintext,
	> .form-select {
		~ label {
			&::after {
				background: none;
			}
		}
	}
}
